<template>
    <TwoCol>
        <div slot="content">
            <SlideOver
                :active="showPrompt"
                @close="showPrompt = false"
                :gutter="false"
            >
                <h2
                    slot="title"
                    class="text-base font-semibold leading-6 text-slate-900"
                >
                    {{ $t("integrations.edit.title") }}
                </h2>
                <template v-slot:content>
                    <IntegrationTokenUpdateForm
                        v-if="integration"
                        :integration="integration"
                        @saved="handleSaved"
                    />
                </template>
            </SlideOver>
            <Heading
                :title="$t('integrations.edit.title').toString()"
                :text="$t('integrations.edit.text').toString()"
                :returnTo="{ name: 'settings.integrations' }"
            />
            <div v-if="!loading && failedAt" class="card mb-4">
                <CardHeading
                    :title="$t('integrations.status').toString()"
                    :text="$t('integrations.not_connected').toString()"
                >
                    <FormButton
                        :loading="syncLoading"
                        :icon="IconEnums.SIGNAL"
                        @click="startSync"
                        :variant="ButtonVariants.PRIMARY"
                        >{{
                            $t("integrations.setup.button", {
                                provider: capitalizeFirstLetter(provider),
                            })
                        }}</FormButton
                    >
                </CardHeading>
                <div class="p-4">
                    <Alert :error="tokenErrorMessage" />
                </div>
            </div>
            <div class="card mb-4">
                <CardHeading
                    :title="$t('integrations.edit.history').toString()"
                >
                    <FormButton
                        v-if="!failedAt"
                        :loading="syncLoading"
                        :icon="IconEnums.REFRESH"
                        @click="startSync"
                        :variant="ButtonVariants.SECONDARY"
                        >{{ $t("integrations.refresh") }}</FormButton
                    >
                </CardHeading>
                <ContentLoading :loading="loading">
                    <ImportsTable :rows="imports" :loading="loading" />
                </ContentLoading>
            </div>
            <div v-if="isActiveDirectoryIntegration" class="card mb-4">
                <CardHeading
                    :title="$t('integrations.edit.domains.title').toString()"
                    :text="$t('integrations.edit.domains.text').toString()"
                >
                    <FormButton
                        :to="{ name: 'settings.domains' }"
                        :variant="ButtonVariants.SECONDARY"
                    >
                        {{ $t("integrations.edit.domains.button") }}</FormButton
                    >
                </CardHeading>
                <DomainTable :rows="domains" :loading="loading" :error="null" />
            </div>
            <div class="card">
                <CardHeading
                    :title="$t('integrations.edit.settings.heading').toString()"
                    :text="$t('integrations.edit.settings.text').toString()"
                />
                <IntegrationSettingsForm @saved="handleSaved" />
            </div>
        </div>
        <div slot="sidebar">
            <Heading
                :title="$t('integrations.edit.statistics').toString()"
                :text="$t('integrations.edit.usage').toString()"
            />
            <div class="card flex flex-col p-4">
                <ContentLoading :loading="loading">
                    <div class="flow-root">
                        <ul role="list" class="-mb-8">
                            <li>
                                <div class="relative pb-8">
                                    <span
                                        class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-slate-200"
                                        aria-hidden="true"
                                    ></span>
                                    <div class="relative flex space-x-3">
                                        <div>
                                            <span
                                                class="flex h-8 w-8 items-center justify-center rounded-full bg-slate-400 ring-8 ring-white"
                                            >
                                                <Icon
                                                    :icon="IconEnums.CALENDAR"
                                                    class="size-5 text-white"
                                                />
                                            </span>
                                        </div>
                                        <div
                                            class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5"
                                        >
                                            <div
                                                class="whitespace-nowrap text-right text-sm text-slate-500"
                                            >
                                                {{
                                                    $t(
                                                        "integrations.created_at",
                                                        {
                                                            time: createdAt
                                                                ? formatDateTime(
                                                                      createdAt
                                                                  )
                                                                : $t(
                                                                      "integrations.never"
                                                                  ),
                                                        }
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="relative pb-8">
                                    <div class="relative flex space-x-3">
                                        <div>
                                            <span
                                                class="flex size-8 items-center justify-center rounded-full bg-emerald-500 ring-8 ring-white"
                                            >
                                                <Icon
                                                    :icon="IconEnums.DOMAIN"
                                                    class="size-5 text-white"
                                                />
                                            </span>
                                        </div>
                                        <div
                                            class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5"
                                        >
                                            <div
                                                class="whitespace-nowrap text-right text-sm text-slate-500"
                                            >
                                                {{
                                                    $t(
                                                        "integrations.last_update",
                                                        {
                                                            time: syncedAt
                                                                ? formatDateTime(
                                                                      syncedAt
                                                                  )
                                                                : $t(
                                                                      "integrations.never"
                                                                  ),
                                                        }
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </ContentLoading>
            </div>
        </div>
    </TwoCol>
</template>

<script>
import { IntegrationStoreNamespacedTypes } from "@/store/modules/integration";
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { formatDateTime } from "@/utils/date";
import { getProperty } from "@/utils/object";
import { capitalizeFirstLetter } from "@/utils/string";

import Bus from "@/bus";
import Heading from "@/components/layout/Heading";
import IntegrationSettingsForm from "@/components/forms/IntegrationSettingsForm";
import TwoCol from "@/components/layout/TwoCol";
import ImportsTable from "@/components/display/integrations/ImportsTable";
import ContentLoading from "@/components/ui/ContentLoading";
import DomainTable from "@/components/display/settings/DomainTable";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";
import CardHeading from "@/components/ui/CardHeading";
import Alert from "@/components/ui/Alert";
import SlideOver from "@/components/ui/SlideOver";
import IntegrationTokenUpdateForm from "@/components/forms/IntegrationTokenUpdateForm";

export default {
    name: "IntegrationSettings",
    components: {
        IntegrationTokenUpdateForm,
        SlideOver,
        Alert,
        CardHeading,
        FormButton,
        DomainTable,
        ContentLoading,
        ImportsTable,
        TwoCol,
        IntegrationSettingsForm,
        Heading,
        Icon,
    },
    data: () => ({
        ButtonVariants,
        IconEnums,
        syncLoading: false,
        showPrompt: false,
    }),
    computed: {
        /**
         * @type {Object}
         */
        workspace() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE
            ]();
        },

        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                IntegrationStoreNamespacedTypes.getters.INTEGRATION_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                IntegrationStoreNamespacedTypes.getters.INTEGRATION_ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        integration() {
            return this.$store.getters[
                IntegrationStoreNamespacedTypes.getters.INTEGRATION
            ]();
        },

        /**
         * @type {string}
         */
        syncedAt() {
            return getProperty(this.integration, "synced_at");
        },

        /**
         * @type {string}
         */
        createdAt() {
            return getProperty(this.integration, "created_at");
        },

        /**
         * @type {Array}
         */
        domains() {
            let domains = getProperty(this.workspace, "domains", []);
            return domains.filter((domain) => {
                return getProperty(domain, "verified_at") !== null;
            });
        },

        /**
         * @type {Array}
         */
        imports() {
            return getProperty(this.integration, "imports", []);
        },

        /**
         * @type {Array}
         */
        provider() {
            return getProperty(this.integration, "provider");
        },

        /**
         * @type {string}
         */
        failedAt() {
            return getProperty(this.integration, "token_failure_at");
        },

        /**
         * @type {Object}
         */
        tokenErrorMessage() {
            if (!this.failedAt) {
                return {
                    message: null,
                };
            }

            return {
                message: this.$t("integrations.token_error", {
                    provider: capitalizeFirstLetter(this.provider),
                    date: formatDateTime(this.failedAt),
                }),
            };
        },

        isActiveDirectoryIntegration() {
            return this.integration.type === "active_directory";
        },
    },
    methods: {
        formatDateTime,
        capitalizeFirstLetter,
        startSync() {
            if (!this.isActiveDirectoryIntegration && this.failedAt) {
                this.showPrompt = true;
                return;
            }

            this.syncLoading = true;
            this.$store
                .dispatch(
                    IntegrationStoreNamespacedTypes.actions.GET_GRANT,
                    this.provider
                )
                .then((response) => {
                    if (!this.error) {
                        if (getProperty(response.data, "synced", false)) {
                            Bus.$emit("flash-message", {
                                text: this.$t("integrations.sync_started"),
                                type: "success",
                            });

                            return this.$store.dispatch(
                                WorkspaceStoreNamespacedTypes.actions
                                    .GET_WORKSPACE,
                                {
                                    force: true,
                                }
                            );
                        }

                        window.location.replace(response.data.url);
                    }
                })
                .finally(() => {
                    this.syncLoading = false;
                });
        },
        handleSaved() {
            if (this.showPrompt) {
                this.showPrompt = false;
            }

            Bus.$emit("flash-message", {
                text: this.$t("settings.saved"),
                type: "success",
            });
        },
    },
};
</script>
