<template>
    <div class="relative mt-4">
        <button
            type="button"
            class="absolute -top-5 left-1/2 z-10 -ml-1.5 rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
            <svg
                @click="handleAddAction(0)"
                class="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
            >
                <path
                    d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"
                />
            </svg>
        </button>
        <ul role="list">
            <AutomationFlowBuilderStep
                v-for="(action, index) in actions"
                :key="action.id"
                :id="action.id"
                :index="index"
                @edit-action="handleEditAction"
                @delete-action="handleDeleteAction"
                @add-action="handleAddAction"
            >
                <Component
                    :is="action.key | snakeToCamel"
                    :index="index"
                    :id="action.id"
                    @save-action="handleSaveAction"
                    @action-selected="handleActionSelect"
                />
            </AutomationFlowBuilderStep>
        </ul>
    </div>
</template>

<script>
import { AutomationStoreNamespacedTypes } from "@/store/modules/automation";

import { snakeToCamel, uuid } from "@/utils/string";

import AutomationFlowBuilderStep from "@/components/display/automation/AutomationFlowBuilderStep";
import ActionSelector from "@/components/display/automation/actions/ActionSelector";
import AssignCourseAction from "@/components/display/automation/actions/AssignCourseAction";
import AssignVideoAction from "@/components/display/automation/actions/AssignVideoAction";
import AssignLessonAction from "@/components/display/automation/actions/AssignLessonAction";
import WaitAction from "@/components/display/automation/actions/WaitAction";
import HaltAction from "@/components/display/automation/actions/HaltAction";
import RemoveTagAction from "@/components/display/automation/actions/RemoveTagAction";
import AddTagAction from "@/components/display/automation/actions/AddTagAction";
import { getProperty } from "@/utils/object";

export default {
    name: "AutomationFlowBuilder",
    components: {
        AutomationFlowBuilderStep,
        ActionSelector,
        AssignCourseAction,
        AssignVideoAction,
        AssignLessonAction,
        WaitAction,
        HaltAction,
        RemoveTagAction,
        AddTagAction,
    },
    filters: {
        snakeToCamel,
    },
    computed: {
        actions() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.ACTIONS
            ]();
        },
        actionError() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION_ACTION_ERROR
            ]();
        },
        hasActionError() {
            return getProperty(this.actionError, "error", null) !== null;
        },
    },
    watch: {
        actions: {
            handler() {
                if (this.actions.length === 0) {
                    this.handleAddAction(0);
                }
            },
            deep: true,
        },
    },
    methods: {
        handleEditAction(index) {
            this.$store.commit(
                AutomationStoreNamespacedTypes.mutations.TOGGLE_ACTION_EDIT,
                {
                    index: index,
                    editing: true,
                    loading: false,
                }
            );
        },
        handleDeleteAction(id, stored, index) {
            if (!stored) {
                return this.$store.commit(
                    AutomationStoreNamespacedTypes.mutations.REMOVE_ACTION,
                    index
                );
            }

            this.$store.dispatch(
                AutomationStoreNamespacedTypes.actions.DELETE_AUTOMATION_ACTION,
                {
                    id: this.$route.params.id,
                    action_id: id,
                    index: index,
                }
            );
        },
        handleAddAction(index, after = false) {
            this.$store.commit(
                AutomationStoreNamespacedTypes.mutations.ADD_ACTION,
                { index, after }
            );
        },
        handleActionSelect(action, index) {
            this.$store.commit(
                AutomationStoreNamespacedTypes.mutations.SELECT_ACTION,
                { action, index }
            );

            if (action === "halt_action" || action === "assign_lesson_action") {
                this.handleSaveAction(uuid(), action, index, {});
            }
        },
        handleSaveAction(id, key, index, attributes) {
            this.$store
                .dispatch(
                    AutomationStoreNamespacedTypes.actions
                        .STORE_AUTOMATION_ACTION,
                    {
                        id: this.$route.params.id,
                        data: {
                            id: id,
                            order: index,
                            key: key,
                            attributes: attributes,
                        },
                    }
                )
                .then(() => {
                    if (
                        key === "halt_action" ||
                        key === "assign_lesson_action"
                    ) {
                        return;
                    }

                    this.$store.commit(
                        AutomationStoreNamespacedTypes.mutations
                            .TOGGLE_ACTION_EDIT,
                        {
                            index: index,
                            editing: this.hasActionError,
                            loading: false,
                        }
                    );
                });
        },
    },
    mounted() {
        this.$store.dispatch(
            AutomationStoreNamespacedTypes.actions.GET_ACTION_DATA
        );

        if (this.actions.length === 0) {
            this.handleAddAction(0);
        }
    },
};
</script>
