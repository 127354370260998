/**
 * A store automation.
 *
 * @typedef {{ automationLoading: boolean, automationError: ErrorOrObject, automations: Array, automation: Object, participants: Object, courses: Array, videos: Array, tags: Array, groups: Array }} AutomationStoreState
 */
import { addNamespace } from "./namespace";
import { getData, postData } from "@/api";
import { getProperty } from "@/utils/object";
import { uuid } from "@/utils/string";
import { getLocale } from "@/lang";

/**
 * The types used in this store
 * @enum {string}
 */
export const AutomationStoreTypes = {
    getters: {
        AUTOMATION_LOADING: "automationLoading",
        AUTOMATION_ERROR: "automationError",
        AUTOMATIONS: "automations",
        AUTOMATION: "automation",
        ACTIONS: "actions",
        ACTION: "action",
        AUTOMATION_ACTION_ERROR: "automationActionError",
        PARTICIPANTS: "participants",
        COURSES: "courses",
        SORTED_COURSES: "sortedCourses",
        VIDEOS: "videos",
        SORTED_VIDEOS: "sortedVideos",
        TAGS: "tags",
        GROUPS: "groups",
        SEGMENTS: "segments",
    },
    actions: {
        RESET_AUTOMATION: "resetAutomation",
        GET_AUTOMATIONS: "getAutomations",
        CREATE_AUTOMATION: "createAutomation",
        GET_AUTOMATION: "getAutomation",
        DUPLICATE_AUTOMATION: "duplicateAutomation",
        DELETE_AUTOMATION: "deleteAutomation",
        GET_ACTION_DATA: "getActionData",
        GET_PARTICIPANTS: "getParticipants",
        TOGGLE_AUTOMATION_STATE: "toggleAutomationState",
        UPDATE_AUTOMATION: "updateAutomation",
        STORE_AUTOMATION_ACTION: "storeAutomationAction",
        DELETE_AUTOMATION_ACTION: "deleteAutomationAction",
        MANUALLY_ENROLL_EMPLOYEES: "manuallyEnrollEmployees",
    },
    mutations: {
        SET_AUTOMATION_LOADING: "setAutomationLoading",
        SET_AUTOMATION_ERROR: "setAutomationError",
        SET_AUTOMATION_ACTION_ERROR: "setAutomationActionError",
        SET_AUTOMATIONS: "setAutomations",
        SET_AUTOMATION: "setAutomation",
        TOGGLE_AUTOMATION_STATE: "toggleAutomationState",
        TOGGLE_ACTION_EDIT: "toggleActionEdit",
        ADD_ACTION: "addAction",
        SELECT_ACTION: "selectAction",
        REMOVE_ACTION: "removeAction",
        UPDATE_ACTION_ATTRIBUTES: "updateActionAttributes",
        UPDATE_ACTION_LOADING_ATTRIBUTES: "updateActionLoadingAttributes",
        SET_PARTICIPANTS: "setParticipants",
        SET_COURSES: "setCourses",
        SET_VIDEOS: "setVideos",
        SET_TAGS: "setTags",
        SET_GROUPS: "setGroups",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const AutomationStoreNamespacedTypes = addNamespace(
    "automation",
    AutomationStoreTypes
);

/**
 * @returns {AutomationStoreState}
 */
export function state() {
    return {
        automationLoading: false,
        automationError: null,
        automationActionError: {
            error: null,
            action_id: null,
        },
        automations: {
            data: [],
            meta: {
                current_page: 1,
                from: 0,
                last_page: 1,
                per_page: 10,
                to: 10,
                total: 0,
            },
        },
        automation: {
            id: null,
            name: null,
            status: null,
            repeat_enabled: false,
            repeat_only_after_halt: true,
            relative_deadlines_enabled: true,
            repeatable_assignments_enabled: false,
            relative_progression_enabled: false,
            notification_flow_enabled: true,
            notification_prelude_enabled: true,
            notification_frequency: "normal",
            trigger: null,
            actions: [],
            group_id: null,
            segment_id: null,
            audience_count: 0,
            created_at: null,
        },
        participants: {
            data: [],
            meta: {
                current_page: 1,
                from: 0,
                last_page: 1,
                per_page: 10,
                to: 10,
                total: 0,
            },
        },
        courses: [],
        videos: [],
        tags: [],
        groups: [],
    };
}

export const getters = {
    [AutomationStoreTypes.getters.AUTOMATION_LOADING]: (state) => () => {
        return state.automationLoading;
    },
    [AutomationStoreTypes.getters.AUTOMATION_ERROR]: (state) => () => {
        return state.automationError;
    },
    [AutomationStoreTypes.getters.AUTOMATIONS]: (state) => () => {
        return state.automations;
    },
    [AutomationStoreTypes.getters.AUTOMATION]: (state) => () => {
        return state.automation;
    },
    [AutomationStoreTypes.getters.ACTIONS]: (state) => () => {
        return state.automation.actions;
    },
    [AutomationStoreTypes.getters.ACTION]: (state) => (actionId) => {
        return state.automation.actions.find(
            (action) => action.id === actionId
        );
    },
    [AutomationStoreTypes.getters.AUTOMATION_ACTION_ERROR]: (state) => () => {
        return state.automationActionError;
    },
    [AutomationStoreTypes.getters.PARTICIPANTS]: (state) => () => {
        return state.participants;
    },
    [AutomationStoreTypes.getters.COURSES]: (state) => () => {
        return state.courses;
    },
    [AutomationStoreTypes.getters.SORTED_COURSES]: (state) => () => {
        return [...state.courses].sort((a, b) =>
            a.title.localeCompare(b.title)
        );
    },
    [AutomationStoreTypes.getters.VIDEOS]: (state) => () => {
        return state.videos;
    },
    [AutomationStoreTypes.getters.SORTED_VIDEOS]: (state) => () => {
        return [...state.videos].sort((a, b) => a.title.localeCompare(b.title));
    },
    [AutomationStoreTypes.getters.TAGS]: (state) => () => {
        return state.tags;
    },
    [AutomationStoreTypes.getters.GROUPS]: (state) => () => {
        return state.groups;
    },
    [AutomationStoreTypes.getters.SEGMENTS]: (state) => (group_id) => {
        if (!group_id) {
            return [];
        }

        let group = state.groups.find((group) => group.id === group_id);

        if (!group) {
            return [];
        }

        return group.segments;
    },
};

export const actions = {
    /**
     * Resets the automation state.
     *
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [AutomationStoreTypes.actions.RESET_AUTOMATION]({ commit }) {
        commit(AutomationStoreTypes.mutations.SET_AUTOMATION_LOADING, true);
        commit(AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR, null);
        commit(AutomationStoreTypes.mutations.SET_PARTICIPANTS, {
            data: [],
            meta: {
                current_page: 1,
                from: 0,
                last_page: 1,
                per_page: 10,
                to: 10,
                total: 0,
            },
        });
    },

    /**
     * Sends the given automation to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} query
     * @return {Promise}
     */
    [AutomationStoreTypes.actions.GET_AUTOMATIONS]({ commit }, query) {
        commit(AutomationStoreTypes.mutations.SET_AUTOMATION_LOADING, true);
        commit(AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR, null);

        return getData("/automations", query)
            .then((response) => {
                commit(AutomationStoreTypes.mutations.SET_AUTOMATIONS, {
                    data: getProperty(response.data, "data", []),
                    meta: getProperty(response.data, "meta", {
                        current_page: 1,
                        from: 0,
                        last_page: 1,
                        per_page: 10,
                        to: 10,
                        total: 0,
                    }),
                });
            })
            .catch((errors) => {
                commit(
                    AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    AutomationStoreTypes.mutations.SET_AUTOMATION_LOADING,
                    false
                );
            });
    },

    /**
     * Sends the given automation to the server.
     *
     * @param {VuexCommit} commit
     * @param data
     * @return {Promise}
     */
    [AutomationStoreTypes.actions.CREATE_AUTOMATION]({ commit }, data) {
        commit(AutomationStoreTypes.mutations.SET_AUTOMATION_LOADING, true);
        commit(AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR, null);

        return postData("/automations", data)
            .catch((errors) => {
                commit(
                    AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    AutomationStoreTypes.mutations.SET_AUTOMATION_LOADING,
                    false
                );
            });
    },

    /**
     * Sends the given automation to the server.
     *
     * @param {VuexCommit} commit
     * @param automation
     * @return {Promise}
     */
    [AutomationStoreTypes.actions.GET_AUTOMATION]({ commit }, automation) {
        commit(AutomationStoreTypes.mutations.SET_AUTOMATION_LOADING, true);
        commit(AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR, null);

        return getData("/automations/" + automation)
            .then((response) => {
                commit(
                    AutomationStoreTypes.mutations.SET_AUTOMATION,
                    response.data
                );
            })
            .catch((errors) => {
                commit(
                    AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    AutomationStoreTypes.mutations.SET_AUTOMATION_LOADING,
                    false
                );
            });
    },

    /**
     * Sends the given automation to the server.
     *
     * @param {VuexCommit} commit
     * @param automation
     * @return {Promise}
     */
    [AutomationStoreTypes.actions.DUPLICATE_AUTOMATION](
        { commit },
        automation
    ) {
        commit(AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR, null);

        return postData("/automations/" + automation + "/duplicate").catch(
            (errors) => {
                commit(
                    AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR,
                    errors
                );
            }
        );
    },

    /**
     * Sends the given automation to the server.
     *
     * @param {VuexCommit} commit
     * @param automation
     * @return {Promise}
     */
    [AutomationStoreTypes.actions.DELETE_AUTOMATION]({ commit }, automation) {
        commit(AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR, null);

        return postData("/automations/" + automation + "/delete").catch(
            (errors) => {
                commit(
                    AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR,
                    errors
                );
            }
        );
    },

    /**
     * Sends the given automation to the server.
     *
     * @param {VuexCommit} commit
     * @param {string} id
     * @param {Object} data
     * @return {Promise}
     */
    [AutomationStoreTypes.actions.GET_PARTICIPANTS]({ commit }, { id, data }) {
        commit(AutomationStoreTypes.mutations.SET_AUTOMATION_LOADING, true);
        commit(AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR, null);

        return getData("/automations/actions/" + id + "/participants", data)
            .then((response) => {
                commit(
                    AutomationStoreTypes.mutations.SET_PARTICIPANTS,
                    response.data
                );
            })
            .catch((errors) => {
                commit(
                    AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    AutomationStoreTypes.mutations.SET_AUTOMATION_LOADING,
                    false
                );
            });
    },

    /**
     * Sends the given automation to the server.
     *
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [AutomationStoreTypes.actions.GET_ACTION_DATA]({ commit }) {
        commit(AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR, null);

        return postData("/automations/data", { locale: getLocale() })
            .then((response) => {
                commit(
                    AutomationStoreTypes.mutations.SET_COURSES,
                    getProperty(response.data, "courses", [])
                );
                commit(
                    AutomationStoreTypes.mutations.SET_VIDEOS,
                    getProperty(response.data, "videos", [])
                );
                commit(
                    AutomationStoreTypes.mutations.SET_TAGS,
                    getProperty(response.data, "tags", [])
                );
                commit(
                    AutomationStoreTypes.mutations.SET_GROUPS,
                    getProperty(response.data, "groups", [])
                );
            })
            .catch((errors) => {
                commit(
                    AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR,
                    errors
                );
            });
    },

    /**
     * Sends the given automation to the server.
     *
     * @param {VuexCommit} commit
     * @param automation
     * @return {Promise}
     */
    [AutomationStoreTypes.actions.TOGGLE_AUTOMATION_STATE](
        { commit },
        automation
    ) {
        commit(AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR, null);

        return postData("/automations/" + automation + "/toggle")
            .then(() => {
                commit(AutomationStoreTypes.mutations.TOGGLE_AUTOMATION_STATE);
            })
            .catch((errors) => {
                commit(
                    AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR,
                    errors
                );
            });
    },

    /**
     * Sends the given automation to the server.
     *
     * @param {VuexCommit} commit
     * @param {string} id
     * @param {Object} data
     * @return {Promise}
     */
    [AutomationStoreTypes.actions.UPDATE_AUTOMATION]({ commit }, { id, data }) {
        commit(AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR, null);

        return postData("/automations/" + id, data)
            .then((response) => {
                commit(
                    AutomationStoreTypes.mutations.SET_AUTOMATION,
                    response.data
                );
            })
            .catch((errors) => {
                commit(
                    AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR,
                    errors
                );
            });
    },

    /**
     * Sends the given automation to the server.
     *
     * @param {VuexCommit} commit
     * @param {string} id
     * @param {Object} data
     * @return {Promise}
     */
    [AutomationStoreTypes.actions.STORE_AUTOMATION_ACTION](
        { commit },
        { id, data }
    ) {
        commit(AutomationStoreTypes.mutations.SET_AUTOMATION_ACTION_ERROR, {
            error: null,
            action_id: null,
        });
        commit(
            AutomationStoreTypes.mutations.UPDATE_ACTION_LOADING_ATTRIBUTES,
            { id: getProperty(data, "id"), loading: true }
        );

        return postData("/automations/actions/" + id, data)
            .then(() => {
                commit(
                    AutomationStoreTypes.mutations.UPDATE_ACTION_ATTRIBUTES,
                    {
                        id: getProperty(data, "id"),
                        attributes: getProperty(data, "attributes", {}),
                    }
                );
                commit(
                    AutomationStoreTypes.mutations
                        .UPDATE_ACTION_LOADING_ATTRIBUTES,
                    { id: getProperty(data, "id"), loading: false }
                );
            })
            .catch((errors) => {
                commit(
                    AutomationStoreTypes.mutations
                        .UPDATE_ACTION_LOADING_ATTRIBUTES,
                    { id: getProperty(data, "id"), loading: false }
                );
                commit(
                    AutomationStoreTypes.mutations.SET_AUTOMATION_ACTION_ERROR,
                    {
                        error: errors,
                        action_id: getProperty(data, "id"),
                    }
                );
            });
    },

    /**
     * Sends the given automation to the server.
     *
     * @param {VuexCommit} commit
     * @param {string} id
     * @param {string} action_id
     * @param {Number} index
     * @return {Promise}
     */
    [AutomationStoreTypes.actions.DELETE_AUTOMATION_ACTION](
        { commit },
        { id, action_id, index }
    ) {
        commit(AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR, null);
        commit(
            AutomationStoreTypes.mutations.UPDATE_ACTION_LOADING_ATTRIBUTES,
            { id: action_id, loading: true }
        );

        return postData("/automations/actions/" + id + "/delete", {
            action_id: action_id,
        })
            .then(() => {
                commit(AutomationStoreTypes.mutations.REMOVE_ACTION, index);
            })
            .catch((errors) => {
                commit(
                    AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR,
                    errors
                );
            });
    },

    /**
     * Sends the given automation to the server.
     *
     * @param {VuexCommit} commit
     * @param {string} id
     * @param {Array} employees
     * @return {Promise}
     */
    [AutomationStoreTypes.actions.MANUALLY_ENROLL_EMPLOYEES](
        { commit },
        { id, employees }
    ) {
        commit(AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR, null);

        return postData("/automations/" + id + "/enroll", {
            employees: employees,
        }).catch((errors) => {
            commit(AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR, errors);
        });
    },
};

export const mutations = {
    /**
     * Sets the loading state for automation.
     *
     * @param {AutomationStoreState} state
     * @param {boolean} loading
     */
    [AutomationStoreTypes.mutations.SET_AUTOMATION_LOADING](state, loading) {
        state.automationLoading = loading;
    },

    /**
     * Sets a new automation error.
     *
     * @param {AutomationStoreState} state
     * @param {ErrorOrObject} error
     */
    [AutomationStoreTypes.mutations.SET_AUTOMATION_ERROR](state, error) {
        state.automationError = error;
    },

    /**
     * Sets a new automation action error.
     *
     * @param {AutomationStoreState} state
     * @param {ErrorOrObject} error
     */
    [AutomationStoreTypes.mutations.SET_AUTOMATION_ACTION_ERROR](state, error) {
        state.automationActionError = error;
    },

    /**
     * Sets the automations list.
     *
     * @param {AutomationStoreState} state
     * @param {Object} data
     */
    [AutomationStoreTypes.mutations.SET_AUTOMATIONS](state, data) {
        state.automations = data;
    },

    /**
     * Sets the automation state.
     *
     * @param {AutomationStoreState} state
     * @param {string|null} id
     * @param {string|null} name
     * @param {string|null} status
     * @param {boolean} repeat_enabled
     * @param {boolean} repeat_only_after_halt
     * @param {boolean} relative_deadlines_enabled
     * @param {boolean} repeatable_assignments_enabled
     * @param {boolean} relative_progression_enabled
     * @param {boolean} notification_flow_enabled
     * @param {boolean} notification_prelude_enabled
     * @param {string} notification_frequency
     * @param {string|null} trigger
     * @param {Array} actions
     * @param {string|null} group_id
     * @param {string|null} segment_id
     * @param {Number} audience_count
     * @param {string|null} created_at
     */
    [AutomationStoreTypes.mutations.SET_AUTOMATION](
        state,
        {
            id = null,
            name = null,
            status = null,
            repeat_enabled = false,
            repeat_only_after_halt = true,
            relative_deadlines_enabled = true,
            repeatable_assignments_enabled = false,
            relative_progression_enabled = false,
            notification_flow_enabled = true,
            notification_prelude_enabled = true,
            notification_frequency = "normal",
            trigger = null,
            actions = [],
            group_id = null,
            segment_id = null,
            audience_count = 0,
            created_at = null,
        }
    ) {
        state.automation.id = id;
        state.automation.name = name;
        state.automation.status = status;
        state.automation.repeat_enabled = repeat_enabled;
        state.automation.repeat_only_after_halt = repeat_only_after_halt;
        state.automation.relative_deadlines_enabled =
            relative_deadlines_enabled;
        state.automation.repeatable_assignments_enabled =
            repeatable_assignments_enabled;
        state.automation.relative_progression_enabled =
            relative_progression_enabled;
        state.automation.notification_flow_enabled = notification_flow_enabled;
        state.automation.notification_prelude_enabled =
            notification_prelude_enabled;
        state.automation.notification_frequency = notification_frequency;
        state.automation.trigger = trigger;
        state.automation.group_id = group_id;
        state.automation.segment_id = segment_id;
        state.automation.audience_count = audience_count;
        state.automation.created_at = created_at;
        state.automation.actions = actions.map((action) => {
            return {
                id: action.id,
                key: action.key,
                order: action.order,
                editing: false,
                stored: true,
                loading: false,
                attributes: action.attributes,
                statistics: action.statistics,
            };
        });
    },

    /**
     * Toggle the editing state of an action.
     *
     * @param {AutomationStoreState} state
     */
    [AutomationStoreTypes.mutations.TOGGLE_AUTOMATION_STATE](state) {
        state.automation.status =
            state.automation.status === "paused" ? "started" : "paused";
    },

    /**
     * Toggle the editing state of an action.
     *
     * @param {AutomationStoreState} state
     * @param {Number} index
     * @param {Boolean} editing
     */
    [AutomationStoreTypes.mutations.TOGGLE_ACTION_EDIT](
        state,
        { index, editing = true }
    ) {
        state.automation.actions[index].editing = editing;
    },

    /**
     * Adds a new action to the automation.
     *
     * @param {AutomationStoreState} state
     * @param {Number} index
     * @param {Boolean} after
     */
    [AutomationStoreTypes.mutations.ADD_ACTION](state, { index, after }) {
        const action = {
            id: uuid(),
            key: "action_selector",
            editing: true,
            stored: false,
            loading: false,
        };
        if (after) {
            state.automation.actions.splice(index + 1, 0, action);
        } else {
            state.automation.actions.splice(index, 0, action);
        }
    },

    /**
     * Select an action from the action selector.
     *
     * @param {AutomationStoreState} state
     * @param {string} action
     * @param {Number} index
     */
    [AutomationStoreTypes.mutations.SELECT_ACTION](state, { action, index }) {
        state.automation.actions.splice(index, 1, {
            id: uuid(),
            key: action,
            order: index + 1,
            editing: true,
            stored: false,
            loading: false,
            attributes: {},
        });
    },

    /**
     * Remove an action from the automation.
     *
     * @param {AutomationStoreState} state
     * @param {Number} index
     */
    [AutomationStoreTypes.mutations.REMOVE_ACTION](state, index) {
        state.automation.actions.splice(index, 1);
    },

    /**
     * Update the attributes of an action.
     *
     * @param {AutomationStoreState} state
     * @param {string} id
     * @param {Object} attributes
     */
    [AutomationStoreTypes.mutations.UPDATE_ACTION_ATTRIBUTES](
        state,
        { id, attributes }
    ) {
        const action = state.automation.actions.find((action) => {
            return action.id === id;
        });

        if (action) {
            action.stored = true;
            action.attributes = attributes;
        }
    },

    /**
     * Update the attributes of an action.
     *
     * @param {AutomationStoreState} state
     * @param {string} id
     * @param {Object} attributes
     */
    [AutomationStoreTypes.mutations.UPDATE_ACTION_LOADING_ATTRIBUTES](
        state,
        { id, loading }
    ) {
        const action = state.automation.actions.find((action) => {
            return action.id === id;
        });

        if (action) {
            action.loading = loading;
        }
    },

    /**
     * Sets the participants list.
     *
     * @param {AutomationStoreState} state
     * @param {Array} data
     */
    [AutomationStoreTypes.mutations.SET_PARTICIPANTS](state, data) {
        state.participants = data;
    },

    /**
     * Sets the courses list.
     *
     * @param {AutomationStoreState} state
     * @param {Array} data
     */
    [AutomationStoreTypes.mutations.SET_COURSES](state, data) {
        state.courses = data;
    },

    /**
     * Sets the videos list.
     *
     * @param {AutomationStoreState} state
     * @param {Array} data
     */
    [AutomationStoreTypes.mutations.SET_VIDEOS](state, data) {
        state.videos = data;
    },

    /**
     * Sets the tags list.
     *
     * @param {AutomationStoreState} state
     * @param {Array} data
     */
    [AutomationStoreTypes.mutations.SET_TAGS](state, data) {
        state.tags = data;
    },

    /**
     * Sets the groups list.
     *
     * @param {AutomationStoreState} state
     * @param {Array} data
     */
    [AutomationStoreTypes.mutations.SET_GROUPS](state, data) {
        state.groups = data;
    },
};

export default {
    namespaced: true,
    Types: AutomationStoreTypes,
    NamespacedTypes: AutomationStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
