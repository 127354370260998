<template>
    <div class="h-full overflow-auto px-4">
        <div class="card">
            <div class="flex items-center justify-center gap-4 pb-2 pt-6">
                <div
                    class="relative flex h-16 w-16 shrink-0 items-center justify-center rounded-2xl border border-white/10 bg-white/5 shadow"
                >
                    <Logo class="relative h-8 w-8" />
                </div>
                <div class="relative w-[6.5rem] overflow-hidden">
                    <div
                        class="absolute left-0 top-0 z-10 h-full w-3 bg-gradient-to-r from-white to-transparent"
                    ></div>
                    <div class="animate-marquee flex w-max justify-end">
                        <div v-for="x in 20" :key="x" class="px-1">
                            <div
                                class="h-1 w-1 shrink-0 rounded-full bg-slate-800/40"
                            ></div>
                        </div>
                    </div>
                    <div
                        class="absolute right-0 top-0 z-10 h-full w-3 bg-gradient-to-r from-transparent to-white"
                    ></div>
                </div>
                <div
                    class="relative flex h-16 w-16 shrink-0 items-center justify-center rounded-2xl border border-white/10 bg-white/5 shadow"
                >
                    <Icon :icon="icon" class="h-8 w-8" />
                </div>
            </div>
            <div v-if="isActive">
                <div v-if="usesSSO" class="flex flex-col p-4">
                    <div
                        class="mb-4 flex flex-col rounded-xl border border-slate-500/20 bg-slate-50/50 p-3 text-xs text-slate-800"
                    >
                        {{
                            $t("integrations.setup.sso", {
                                provider: capitalizeFirstLetter(key),
                            })
                        }}
                    </div>
                    <FormButton
                        @click="getGrant"
                        :loading="loading"
                        :variant="ButtonVariants.SECONDARY"
                        :icon="IconEnums.SIGNAL"
                    >
                        {{
                            $t("integrations.setup.button", {
                                provider: capitalizeFirstLetter(key),
                            })
                        }}
                    </FormButton>
                </div>
                <ValidationObserver
                    v-else
                    ref="integrationSetupForm"
                    v-slot="{ passes }"
                >
                    <form @submit.prevent="passes(storeIntegration)">
                        <div class="flex flex-col p-4">
                            <Alert :error="error" />
                            <FormFieldSet :class="{ 'mt-4': error }">
                                <FormFieldOutline class="space-y-4">
                                    <FormSelect
                                        v-model="form.region"
                                        name="data_region"
                                    >
                                        <option
                                            v-for="region in regions"
                                            :key="region.id"
                                            :value="region.id"
                                        >
                                            {{ region.name }}
                                        </option>
                                    </FormSelect>
                                    <hr />
                                    <FormPassword
                                        v-model="form.access_token"
                                        type="password"
                                        name="access_token"
                                        :show-indicator="false"
                                    />
                                </FormFieldOutline>
                            </FormFieldSet>
                            <div class="flex flex-row justify-end">
                                <FormButton type="submit" :loading="loading">
                                    {{
                                        $t("integrations.setup.button", {
                                            provider: key,
                                        })
                                    }}
                                </FormButton>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<script>
import { IntegrationStoreNamespacedTypes } from "@/store/modules/integration";

import { getProperty } from "@/utils/object";
import { capitalizeFirstLetter } from "@/utils/string";
import { IconEnums } from "@/utils/icons";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";

import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import FormSelect from "@/components/forms/fields/FormSelect";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormFieldOutline from "@/components/forms/fields/FormFieldOutline";
import Logo from "@/components/ui/Logo";
import Icon from "@/components/icons/BaseIcon";
import FormPassword from "@/components/forms/fields/FormPassword";

export default {
    name: "IntegrationSetupForm",
    components: {
        FormPassword,
        Icon,
        Logo,
        FormFieldOutline,
        FormFieldSet,
        FormSelect,
        FormButton,
        Alert,
        ValidationObserver,
    },
    props: {
        integration: {
            type: Object,
            default: () => {
                return {
                    key: null,
                    active: false,
                    sso: false,
                    hasSettings: false,
                };
            },
        },
    },
    data: () => ({
        IconEnums,
        ButtonVariants,
        form: {
            provider: null,
            type: null,
            region: "eu",
            access_token: null,
        },
        regions: [
            {
                id: "global",
                name: "Global",
            },
            {
                id: "eu",
                name: "EU",
            },
        ],
        loading: false,
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        error() {
            return this.$store.getters[
                IntegrationStoreNamespacedTypes.getters.INTEGRATION_ERROR
            ]();
        },

        /**
         * @type {string}
         */
        key() {
            return getProperty(this.integration, "key");
        },

        /**
         * @type {Object}
         */
        icon() {
            return this.IconEnums[this.key.toUpperCase()];
        },

        /**
         * @type {boolean}
         */
        isActive() {
            return this.key && getProperty(this.integration, "active", false);
        },

        /**
         * @type {boolean}
         */
        usesSSO() {
            return getProperty(this.integration, "sso");
        },
    },
    methods: {
        capitalizeFirstLetter,
        getGrant() {
            this.loading = true;
            this.$store
                .dispatch(
                    IntegrationStoreNamespacedTypes.actions.GET_GRANT,
                    this.key
                )
                .then((response) => {
                    if (!this.error && response.data.url) {
                        window.location.replace(response.data.url);
                    } else {
                        this.loading = false;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Sends authentication credentials to the server.
         */
        storeIntegration() {
            this.loading = true;
            this.form.type = "compliance"; // Only type for now
            this.form.provider = this.key;

            this.$store
                .dispatch(
                    IntegrationStoreNamespacedTypes.actions.STORE_INTEGRATION,
                    { form: this.form }
                )
                .then(() => {
                    this.loading = false;
                    if (!this.error) {
                        this.$emit("saved");
                    }
                });
        },
    },

    beforeCreate() {
        this.$store.commit(
            IntegrationStoreNamespacedTypes.mutations.SET_INTEGRATION_ERROR,
            null
        );
    },
};
</script>
