<template>
    <path
        d="M19.085 22a10.792 10.792 0 0 0-7.184-2.713c-2.75 0-5.264 1.02-7.184 2.713H0l.11-.157 3.123-5.77L8.09 7.088l2.343 4.312-2.655 4.908a14.327 14.327 0 0 1 4.123-.596c1.437 0 2.826.204 4.138.612l-1.39-2.572v-.016l-.312-.58-1.359-2.509-1.077-1.991-2.343-4.312.937-1.725L11.916 0l11.776 21.843.11.157h-4.717Z"
        fill="#000"
        fill-rule="evenodd"
    />
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconDrata",
    data: () => ({
        viewBoxHeight: 22,
        viewBoxWidth: 24,
    }),
};
</script>
