<template>
    <div class="relative">
        <ul role="list">
            <AutomationFlowStep
                v-for="(action, index) in actions"
                :key="action.id"
                :automation-id="automationId"
                :action-id="action.id"
                :index="action.order || index + 1"
            />
        </ul>
    </div>
</template>

<script>
import { AutomationStoreNamespacedTypes } from "@/store/modules/automation";
import AutomationFlowStep from "@/components/display/automation/AutomationFlowStep";

export default {
    name: "AutomationFlow",
    components: {
        AutomationFlowStep,
    },
    props: {
        automationId: {
            type: String,
            default: "",
        },
    },
    computed: {
        actions() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.ACTIONS
            ]();
        },
    },
    mounted() {
        this.$store.dispatch(
            AutomationStoreNamespacedTypes.actions.GET_ACTION_DATA
        );
    },
};
</script>
