<template>
    <WorkspaceSettingsPage>
        <SlideOver
            :active="showPrompt"
            @close="showPrompt = false"
            :gutter="false"
        >
            <h2
                slot="title"
                class="text-base font-semibold leading-6 text-slate-900"
            >
                {{ $t("integrations.setup.title") }}
            </h2>
            <template v-slot:content>
                <IntegrationSetupForm
                    :integration="integration"
                    @saved="handleSavedIntegration"
                />
            </template>
        </SlideOver>
        <div class="card">
            <CardHeading
                :title="$t('integrations.heading').toString()"
                :text="$t('integrations.text').toString()"
                :loading="loading"
            />
            <div v-show="integrationError" class="border-b p-4">
                <Alert :error="integrationError" />
            </div>
            <ul role="list" class="divide-y divide-slate-200">
                <IntegrationRow
                    v-for="integration in integrations"
                    :key="integration.key"
                    :integration="integration"
                    @open="showSetupPrompt"
                />
            </ul>
        </div>
    </WorkspaceSettingsPage>
</template>

<script>
import { IntegrationStoreNamespacedTypes } from "@/store/modules/integration";
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { integrations } from "@/utils/enums";
import IntegrationRow from "@/components/display/integrations/IntegrationRow";
import Alert from "@/components/ui/Alert";
import WorkspaceSettingsPage from "@/components/layout/WorkspaceSettingsPage";
import CardHeading from "@/components/ui/CardHeading";
import SlideOver from "@/components/ui/SlideOver";
import IntegrationSetupForm from "@/components/forms/IntegrationSetupForm";
import Bus from "@/bus";

export default {
    name: "WorkspaceSettings",
    components: {
        IntegrationSetupForm,
        SlideOver,
        CardHeading,
        WorkspaceSettingsPage,
        Alert,
        IntegrationRow,
    },
    data: () => ({
        integrations,
        showPrompt: false,
        integration: {
            key: null,
            active: false,
            sso: false,
            hasSettings: false,
        },
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        integrationError() {
            return this.$store.getters[
                IntegrationStoreNamespacedTypes.getters.INTEGRATION_ERROR
            ]();
        },
    },
    methods: {
        /**
         * @param {Object} integration
         */
        showSetupPrompt(integration) {
            this.integration = integration;
            this.showPrompt = true;
        },
        handleSavedIntegration() {
            this.$store.dispatch(
                WorkspaceStoreNamespacedTypes.actions.GET_WORKSPACE,
                {
                    force: true,
                }
            );
            this.showPrompt = false;

            Bus.$emit("flash-message", {
                text: this.$t("settings.saved"),
                type: "success",
            });
        },
    },
    beforeCreate() {
        this.$store.commit(
            IntegrationStoreNamespacedTypes.mutations.SET_INTEGRATION_ERROR,
            null
        );
    },
};
</script>
