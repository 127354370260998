<template>
    <div class="h-full overflow-auto px-4">
        <div class="card">
            <ValidationObserver ref="integrationSetupForm" v-slot="{ passes }">
                <form @submit.prevent="passes(updateToken)">
                    <div class="flex flex-col p-4">
                        <Alert :error="error" />
                        <FormFieldSet :class="{ 'mt-4': error }">
                            <FormFieldOutline class="space-y-4">
                                <FormSelect
                                    v-model="form.region"
                                    name="data_region"
                                >
                                    <option
                                        v-for="region in regions"
                                        :key="region.id"
                                        :value="region.id"
                                    >
                                        {{ region.name }}
                                    </option>
                                </FormSelect>
                                <hr />
                                <FormPassword
                                    v-model="form.access_token"
                                    type="password"
                                    name="access_token"
                                    :show-indicator="false"
                                />
                            </FormFieldOutline>
                        </FormFieldSet>
                        <div class="flex flex-row justify-end">
                            <FormButton type="submit" :loading="loading">
                                {{
                                    $t("integrations.setup.button", {
                                        provider:
                                            capitalizeFirstLetter(provider),
                                    })
                                }}
                            </FormButton>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import { IntegrationStoreNamespacedTypes } from "@/store/modules/integration";

import { IconEnums } from "@/utils/icons";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";

import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import FormSelect from "@/components/forms/fields/FormSelect";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormFieldOutline from "@/components/forms/fields/FormFieldOutline";
import FormPassword from "@/components/forms/fields/FormPassword";
import { getProperty } from "@/utils/object";
import { capitalizeFirstLetter } from "@/utils/string";

export default {
    name: "IntegrationTokenUpdateForm",
    components: {
        FormPassword,
        FormFieldOutline,
        FormFieldSet,
        FormSelect,
        FormButton,
        Alert,
        ValidationObserver,
    },
    props: {
        integration: {
            type: Object,
        },
    },
    data: () => ({
        IconEnums,
        ButtonVariants,
        form: {
            region: "eu",
            access_token: null,
        },
        regions: [
            {
                id: "global",
                name: "Global",
            },
            {
                id: "eu",
                name: "EU",
            },
        ],
        loading: false,
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        error() {
            return this.$store.getters[
                IntegrationStoreNamespacedTypes.getters.INTEGRATION_ERROR
            ]();
        },

        provider() {
            return getProperty(this.integration, "provider", null);
        },
    },
    methods: {
        capitalizeFirstLetter,

        /**
         * Sends authentication credentials to the server.
         */
        updateToken() {
            this.loading = true;
            this.$store
                .dispatch(
                    IntegrationStoreNamespacedTypes.actions
                        .UPDATE_INTEGRATION_TOKEN,
                    { integration: this.$route.params.id, form: this.form }
                )
                .then(() => {
                    this.loading = false;
                    if (!this.error) {
                        this.$emit("saved");
                    }
                });
        },
    },
    beforeCreate() {
        this.$store.commit(
            IntegrationStoreNamespacedTypes.mutations.SET_INTEGRATION_ERROR,
            null
        );
    },
};
</script>
