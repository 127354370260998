<template>
    <path
        d="M23.177.001h-3.073a.83.83 0 0 0-.694 1.288l.527.8c.45.684.537 1.544.235 2.305l-5.514 13.868c-.253.668-.51 1.125-.628 1.238a.192.192 0 0 1-.248.017l-.29-.231c-.093-.07-.087-.17-.002-.265.161-.215.262-.474.318-.71.068-.282.045-.58-.058-.851L9.08 5.116a3.442 3.442 0 0 1 .033-2.522l.5-1.219A.997.997 0 0 0 8.69 0H1.166C.152 0-.377 1.207.31 1.952l.479.52a10.52 10.52 0 0 1 2.05 3.27l6.705 17.522c.175.444.604.736 1.082.736h3.712c.477 0 .906-.291 1.082-.734l8.684-21.9a.997.997 0 0 0-.927-1.365Z"
        fill="#240642"
        fill-rule="nonzero"
    />
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconVanta",
    data: () => ({
        viewBoxHeight: 24,
        viewBoxWidth: 24,
    }),
};
</script>
