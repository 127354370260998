export const currencies = [
    { value: "DKK", text: "DKK" },
    { value: "USD", text: "USD" },
];

export const localeEn = "en_US";
export const localeDa = "da_DK";

export const locales = [
    { value: "en_US", text: "english" },
    { value: "da_DK", text: "danish" },
    { value: "sv_SE", text: "swedish" },
    { value: "de_DE", text: "german" },
    { value: "nb_NO", text: "norwegian" },
    { value: "fi_FI", text: "finnish" },
];

export const industries = [
    "aerospace_defence",
    "wealth_management",
    "automotive",
    "banking",
    "capital_infrastructure",
    "consumer_markets",
    "energy_resources",
    "engineering_construction",
    "financial_services",
    "paper_packaging",
    "government_public",
    "healthcare",
    "hospitality_leisure",
    "industrial_manufacturing",
    "insurance",
    "media",
    "pharmaceuticals",
    "private_equity",
    "technology",
    "telecommunications",
    "transportation",
];

export const integrations = [
    {
        key: "microsoft",
        active: true,
        sso: true,
        hasSettings: false,
    },
    {
        key: "google",
        active: true,
        sso: true,
        hasSettings: false,
    },
    {
        key: "slack",
        active: true,
        sso: true,
        hasSettings: false,
    },
    {
        key: "sap",
        active: false,
        sso: true,
        hasSettings: true,
    },
    {
        key: "drata",
        active: true,
        sso: false,
        hasSettings: false,
    },
    {
        key: "vanta",
        active: false,
        sso: false,
        hasSettings: false,
    },
];
